import "./styles.scss";

import * as React from "react";
import * as operatingCompaniesActions from "./../../actions/operatingCompaniesActions";
import * as segmentActions from "./../../actions/segmentActions";

import { Content } from "./../../components/Content/Content";
import { connect } from "react-redux";
import genericLogoBig from "./../../img/logos/generic-white-large.svg";
import genericLogoSmall from "./../../img/logos/generic-white-small.svg";
import operatingCompanyIds from "../../constants/operatingCompanyIds";

class Locations extends React.PureComponent<*> {
  componentDidMount() {
    const {
      operatingCompanies,
      getOperatingCompanies,
      trackPageViewed
    } = this.props;

    if (!operatingCompanies.length) {
      getOperatingCompanies();
    }

    if (trackPageViewed) {
      trackPageViewed("locations");
    }
  }

  handleUpdateSelectedOperatingCompany = operatingCompany => {
    const { updateSelectedOperatingCompany } = this.props;

    updateSelectedOperatingCompany(operatingCompany);
  };

  render() {
    const { operatingCompanies = [] } = this.props;

    const operatingCompaniesMinusETI = operatingCompanies.filter((item) => item.id !== operatingCompanyIds.Texas );
    return (
      <Content isHeaderVisible={false} isFooterVisible={false}>
        <div className="location-modal-layout">
          <span className="location-logo">
            <img src={genericLogoBig} alt="" />
            <img src={genericLogoSmall} className="mobile" alt="" />
          </span>
          <div>
            <div className="location-modal-layout__aligner">
              <h3>
                You’re just a few steps away from running with solar power.
              </h3>

              <div className="plan-chooser">
                <span className="plan-chooser__title">
                  Choose your location:
                </span>
                <ul>
                  {operatingCompaniesMinusETI.map((operatingCompany, index) => {
                    
                      if (operatingCompany.abbreviation === "EAL-B") {
                        return (
                          <li key={operatingCompany.name}>
                            <button
                              onClick={() =>
                                this.handleUpdateSelectedOperatingCompany(
                                  operatingCompany
                                )
                              }
                            >
                              Arkansas SEPO B
                            </button>
                          </li>
                        );
                      }
                      else {
                        return (
                          <li key={operatingCompany.name}>
                            <button
                              onClick={() =>
                                this.handleUpdateSelectedOperatingCompany(
                                  operatingCompany
                                )
                              }
                            >
                              {operatingCompany.stateFullName ||
                                operatingCompany.name}
                            </button>
                          </li>
                        );
                      }
                    
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getOperatingCompanies: () =>
    dispatch(operatingCompaniesActions.getOperatingCompanies()),
  updateSelectedOperatingCompany: selectedOperatingCompany =>
    dispatch(
      operatingCompaniesActions.updateSelectedOperatingCompany(
        selectedOperatingCompany
      )
    ),
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName))
});

const mapStateToProps = state => ({
  operatingCompanies: state.operatingCompaniesReducer.operatingCompanies
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
