import {
  CHECKED_FOR_SELECTED_OPERATING_COMPANY,
  GET_OPERATING_COMPANIES_SUCCESS,
  GET_SOLAR_FARM_LOCATIONS_SUCCESS,
  UPDATE_SELECTED_OPERATING_COMPANY_SUCCESS
} from "./../actions/types";

import operatingCompanyIds from "../constants/operatingCompanyIds";
import signupTypes from "../constants/signupTypes";

const initialState = {
  operatingCompanies: [],
  checkedForSelectedOperatingCompany: false
};

const defaultVisibleStates = {
  aboutUs: true,
  contactUs: true,
  whySolarity: true
};

//TODO: determine if there is a way to derive more of this from the payload
const getVisibleStates = (payload = {}) => {
  const solarFarms = !payload.isWaitlisted;

  switch (payload.id) {
   /* case operatingCompanyIds.Texas:
      return {
        ...defaultVisibleStates,
        noFarms: true,
        faq: true,
        plans: payload.isActive,
        solarFarms
      }; */
    case operatingCompanyIds.Mississippi:
      return {
        ...defaultVisibleStates,
        faq: true,
        plans: payload.isActive,
        solarFarms
      };
    case operatingCompanyIds.Arkansas:
      return {
        ...defaultVisibleStates,
        faq: true,
        plans: payload.isActive,
        solarFarms
      };
    case operatingCompanyIds.Louisiana:
      return {
        ...defaultVisibleStates,
        noFarms: true,
        solarFarms: false
      };
    case operatingCompanyIds.Other:
      return {
        ...defaultVisibleStates,
        contactUs: false
      };
    default:
      return {};
  }
};

function getSignupType(operatingCompany) {
  if (operatingCompany.isEnrollmentEnabled && operatingCompany.hasCapacity) {
    return signupTypes.Signup;
  } else if (!operatingCompany.isWaitlisted && operatingCompany.hasCapacity) {
    return signupTypes.Reserve;
  } else {
    return signupTypes.JoinWaitlist;
  }
}

const operatingCompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATING_COMPANIES_SUCCESS: {
      return {
        ...state,
        operatingCompanies: [].concat.apply([], action.payload)
      };
    }
    case GET_SOLAR_FARM_LOCATIONS_SUCCESS: {
      return {
        ...state,
        solarFarmLocations: [].concat.apply([], action.payload)
      };
    }
    case UPDATE_SELECTED_OPERATING_COMPANY_SUCCESS: {
      return {
        ...state,
        selectedOperatingCompany: {
          ...action.payload,
          signupType: getSignupType(action.payload),
          visibility: getVisibleStates(action.payload)
        }
      };
    }
    case CHECKED_FOR_SELECTED_OPERATING_COMPANY: {
      return {
        ...state,
        checkedForSelectedOperatingCompany: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default operatingCompaniesReducer;
